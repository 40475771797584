<template>
  <VRow
    :no-gutters="noGutters"
    align="center"
  >
    <VCol v-if="showDate">
      <VMenu
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <VTextField
            :value="dateFormatted"
            :label="normalizedDateLable"
            placeholder="ДД.ММ.ГГГГ"
            append-icon="fal fa-calendar"
            readonly
            :clearable="!!date && !dateRequire"
            v-bind="attrs"
            @click:append="dateMenu = true"
            v-on="on"
            @input="handleDate"
          />
        </template>

        <VDatePicker
          :value="date"
          no-title
          @input="handleDate"
        />
      </VMenu>
    </VCol>

    <VCol v-if="showTime">
      <VMenu
        ref="time"
        v-model="timeMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <VTextField
            :value="time"
            :label="normalizedTimeLable"
            placeholder="--:--"
            readonly
            :clearable="!!time && !timeRequire"
            append-icon="fal fa-clock"
            v-bind="attrs"
            @click:append="timeMenu = true"
            v-on="on"
            @input="handleTime"
          />
        </template>

        <VTimePicker
          v-if="timeMenu"
          :value="time"
          format="24hr"
          full-width
          @click:minute="$refs.time.save(time)"
          @input="handleTime"
        />
      </VMenu>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'DateTime',

  props: {
    dateLable: {
      type: String,
      default: null,
    },
    timeLable: {
      type: String,
      default: null,
    },
    dateRequire: {
      type: Boolean,
      default: false,
    },
    timeRequire: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: null,
      dateMenu: false,
      time: null,
      timeMenu: false,
    };
  },

  computed: {
    dateFormatted: {
      get() {
        return this.formatdate(this.date);
      },
      set() {
        this.date = null;
      },
    },
    showDate() {
      return !!this.dateLable;
    },
    showTime() {
      return !!this.timeLable;
    },
    noGutters() {
      return !this.showDate || !this.showTime;
    },
    normalizedDateLable() {
      return this.dateRequire ? this.dateLable : `${this.dateLable} (опционально)`;
    },
    normalizedTimeLable() {
      return this.timeRequire ? this.timeLable : `${this.timeLable} (опционально)`;
    },
  },

  methods: {
    formatdate(date) {
      if (!date) return null;
      return this.$dayjs(date).format('DD.MM.YYYY');
    },

    handleTime(value) {
      this.timeMenu = false;
      this.time = value;

      const datetime = this.$dayjs([this.date, this.time].join(' '), 'YYYY-MM-DD HH:mm');

      this.$emit('input', datetime.format());
    },

    handleDate(value) {
      this.dateMenu = false;
      this.date = value;

      const datetime = this.$dayjs([this.date, this.time].join(' '), 'YYYY-MM-DD HH:mm');

      this.$emit('input', datetime.format());
    },
  },
};
</script>
