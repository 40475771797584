<template>
  <VList disabled>
    <VCardTitle>Процедуры</VCardTitle>

    <VListItemGroup>
      <VListItem
        v-for="procedure in procedures"
        :key="procedure.id"
        two-line
      >
        <VListItemContent>
          <VListItemTitle>
            {{ procedure.name }}
          </VListItemTitle>

          <VChipGroup show-arrows>
            <VChip
              v-for="step in procedure.steps"
              :key="step.id"
            >
              {{ step.name }}
            </VChip>
          </VChipGroup>
        </VListItemContent>
      </VListItem>
    </VListItemGroup>
  </VList>
</template>

<script>
export default {
  name: 'ProceduresList',

  props: {
    procedures: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
