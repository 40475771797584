<template>
  <VList disabled>
    <VCardTitle>Шаги</VCardTitle>
    <VListItemGroup>
      <VListItem
        v-for="step in steps"
        :key="step.id"
      >
        <VListItemContent>
          <VListItemTitle>
            {{ step.name }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </VListItemGroup>
  </VList>
</template>

<script>
export default {
  name: 'StepsList',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
