<template>
  <!-- FIXME: пока грязное решение -->
  <!-- eslint-disable vue/no-mutating-props -->
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <!-- Note: в api нет имени у группы -->
          <!--
          <VRow align="center">
            <VCol>
              <VTextField
                label="Название"
                placeholder="Введите название"
              />
            </VCol>
          </VRow>
          -->

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="group.companyId"
                :loading="companies.loading"
                :search-input.sync="searchCompany"
                :filter="customFilter"
                :items="companies.items"
                item-text="id"
                item-value="id"
                label="Компания"
                placeholder="Выберите компанию"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="group.teamId"
                :items="teams"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Команда (опционально)"
                placeholder="Выберите команду"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                v-model="group.userId"
                label="Пользователь (опционально)"
                placeholder="Выберите пользователя"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardText v-if="group.procedures.length > 0">
      <VDivider />

      <ProceduresList :procedures="group.procedures" />

      <VDivider />
    </VCardText>

    <VCardText>
      <VBtn
        text
        color="primary"
        @click="handleAddProcedure"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Добавить процедуру
      </VBtn>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>

    <VDialog
      v-if="addProcedureDialog"
      v-model="addProcedureDialog"
      persistent
      max-width="1440px"
    >
      <ProcedureForm
        :procedure="procedure"
        :competencies="competencies"
        :skills="skills"
        :instruments="instruments"
        @submit="handleSubmitProcedure"
        @cancel="handleCancelProcedure"
        @update:searchSkills="$emit('update:searchSkills', $event)"
        @update:searchCompetencies="$emit('update:searchCompetencies', $event)"
      />
    </VDialog>
  </VCard>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
import _ from 'lodash';
import ProcedureForm from './procedures/ProcedureForm.vue';
import ProceduresList from './procedures/ProceduresList.vue';

const DEFAULT_PROCEDURE = {
  name: null,
  skillId: null,
  competencyId: null,
  cooldown: null,
  continuous: null,
  startAt: null,
  finishedAt: null,
  steps: [],
};

export default {
  name: 'AssessmentForm',

  components: { ProceduresList, ProcedureForm },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      required: true,
    },
    companies: {
      type: Object,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
    competencies: {
      type: Object,
      required: true,
    },
    skills: {
      type: Object,
      required: true,
    },
    instruments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      addProcedureDialog: false,
      procedure: { ...DEFAULT_PROCEDURE },
      searchCompany: null,
    };
  },

  computed: {
    normalizedTitle() {
      return 'Создание оценки';
    },

    normalizedSubmit() {
      return 'Создать';
    },
  },
  watch: {
    searchCompany() {
      this.$emit('update:searchCompany', this.searchCompany);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleAddProcedure() {
      this.addProcedureDialog = true;
    },

    // HACK: временно грязное решение
    handleSubmitProcedure() {
      this.addProcedureDialog = false;
      /* eslint-disable-next-line vue/no-mutating-props */
      this.group.procedures.push(_.cloneDeep(this.procedure));
      this.procedure = { ...DEFAULT_PROCEDURE };
      this.procedure.steps = [];
    },

    // HACK: временно грязное решение
    handleCancelProcedure() {
      this.addProcedureDialog = false;
      this.procedure = { ...DEFAULT_PROCEDURE };
      this.procedure.steps = [];
    },

    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
