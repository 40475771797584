var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('VCardTitle',[_vm._v(" "+_vm._s(_vm.normalizedTitle)+" ")]),_c('VCardText',[_c('VForm',[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"loading":_vm.companies.loading,"search-input":_vm.searchCompany,"filter":_vm.customFilter,"items":_vm.companies.items,"item-text":"id","item-value":"id","label":"Компания","placeholder":"Выберите компанию"},on:{"update:searchInput":function($event){_vm.searchCompany=$event},"update:search-input":function($event){_vm.searchCompany=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}]),model:{value:(_vm.group.companyId),callback:function ($$v) {_vm.$set(_vm.group, "companyId", $$v)},expression:"group.companyId"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"items":_vm.teams,"filter":_vm.customFilter,"item-text":"name","item-value":"id","label":"Команда (опционально)","placeholder":"Выберите команду"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}]),model:{value:(_vm.group.teamId),callback:function ($$v) {_vm.$set(_vm.group, "teamId", $$v)},expression:"group.teamId"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextField',{attrs:{"label":"Пользователь (опционально)","placeholder":"Выберите пользователя"},model:{value:(_vm.group.userId),callback:function ($$v) {_vm.$set(_vm.group, "userId", $$v)},expression:"group.userId"}})],1)],1)],1)],1)],1),(_vm.group.procedures.length > 0)?_c('VCardText',[_c('VDivider'),_c('ProceduresList',{attrs:{"procedures":_vm.group.procedures}}),_c('VDivider')],1):_vm._e(),_c('VCardText',[_c('VBtn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.handleAddProcedure}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Добавить процедуру ")],1)],1),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Отмена ")]),_c('VBtn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.normalizedSubmit)+" ")])],1),(_vm.addProcedureDialog)?_c('VDialog',{attrs:{"persistent":"","max-width":"1440px"},model:{value:(_vm.addProcedureDialog),callback:function ($$v) {_vm.addProcedureDialog=$$v},expression:"addProcedureDialog"}},[_c('ProcedureForm',{attrs:{"procedure":_vm.procedure,"competencies":_vm.competencies,"skills":_vm.skills,"instruments":_vm.instruments},on:{"submit":_vm.handleSubmitProcedure,"cancel":_vm.handleCancelProcedure,"update:searchSkills":function($event){return _vm.$emit('update:searchSkills', $event)},"update:searchCompetencies":function($event){return _vm.$emit('update:searchCompetencies', $event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }