<template>
  <TTView>
    <VRow>
      <VCol>
        <AssessmentForm
          :loading="loading"
          :group="group"
          :companies="companies"
          :teams="teams"
          :competencies="competencies"
          :skills="skills"
          :instruments="instruments"
          @submit="handleCreateAssessment"
          @update:searchCompany="fetchCompanies($event)"
          @update:searchSkills="fetchSkills($event)"
          @update:searchCompetencies="fetchCompetencies($event)"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import AssessmentForm from '../../components/assessments/AssessmentForm.vue';

const createAssessmentDTO = {
  name: null,
  companyId: null,
  teamId: null,
  userId: null,
  procedures: [],
};

// FIXME: есть проблема с "сохранением" procedures.steps по ссылке из-за грязных решений
export default {
  name: 'AssessmentCreate',

  components: {
    AssessmentForm,
  },

  inject: ['Names'],

  data() {
    return {
      group: _.cloneDeep(createAssessmentDTO),
      loading: false,
      companies: { loading: true, items: [] },
      instruments: [],
      skills: { loading: true, items: [] },
      teams: [],
      competencies: { loading: true, items: [] },
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'group.companyId': [
      function flush() {
        this.group.teamId = null;
        this.group.procedures = [];
      },

      async function fetchTeams(companyId) {
        try {
          this.loading = true;

          const { teams } = await this.$di.api.Orgstructure.teamIndex(companyId);

          this.teams = teams;
        } catch (err) {
          this.$di.notify.errorHandler(err);
        } finally {
          this.loading = false;
        }
      },
    ],
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const [{ instruments }] = await Promise.all([
          this.$di.api.Assessment.InstrumentsIndex(),
          this.fetchCompanies(),
          this.fetchSkills(),
          this.fetchCompetencies(),
        ]);
        this.instruments = instruments;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchCompanies(query = '') {
      this.companies.loading = true;
      try {
        const { companies } = await this.$di.api.Account.searchCompanies({ query });
        this.companies.items = companies;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.companies.loading = false;
      }
    },

    async fetchSkills(query = '') {
      this.skills.loading = true;
      try {
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch({ query });
        this.skills.items = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.skills.loading = false;
      }
    },

    async fetchCompetencies(query = '') {
      this.competencies.loading = true;
      try {
        const { competencies } = await this.$di.api.CompetencyMatrix.CompetenciesSearch({ query });
        this.competencies.items = competencies;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.competencies.loading = false;
      }
    },

    async handleCreateAssessment() {
      try {
        this.loading = true;
        const dto = _.cloneDeep(this.group);
        const app = new this.$di.ddd.Assessment();

        await app.services.assessment.createAssessment(dto);

        this.$di.notify.snackSuccess('Оценка успешно создана');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        this.$router.push({ name: this.Names.R_LEARNING_ASSESSMENTS_LIST });
      }
    },
  },
};
</script>
