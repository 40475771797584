<template>
  <!-- FIXME: временно грязное решение -->
  <!-- eslint-disable vue/no-mutating-props -->
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                v-model="procedure.name"
                label="Название"
                placeholder="Введите название"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="procedure.competencyId"
                :loading="competencies.loading"
                :filter="customFilter"
                :search-input.sync="searchCompetencies"
                :items="competencies.items"
                item-text="id"
                item-value="id"
                label="Компетенция"
                placeholder="Выберите компетенцию"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="procedure.skillId"
                :loading="skills.loading"
                :search-input.sync="searchSkills"
                :items="skills.items"
                :filter="customFilter"
                item-text="id"
                item-value="id"
                label="Навык"
                placeholder="Выберите навык"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <DateTime
            v-model="procedure.startAt"
            date-lable="Дата начала"
            time-lable="Время начала"
          />

          <DateTime
            v-model="procedure.finishedAt"
            date-lable="Дата окончания"
            time-lable="Время окончания"
          />

          <VRow align="center">
            <VCol>
              <VTextField
                v-model="procedure.cooldown"
                label="Куллдаун (опционально)"
                placeholder="-"
                type="number"
                min="0"
              />
            </VCol>

            <VCol>
              <VCheckbox
                v-model="procedure.continuous"
                label="Цикличность (опционально)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardText v-if="procedure.steps.length > 0">
      <VDivider />

      <StepsList :steps="procedure.steps" />

      <VDivider />
    </VCardText>

    <VCardText v-if="!stepFormIsHidden">
      <StepForm
        :step="step"
        :instruments="instruments"
        @submit="handleSubmitStep"
        @cancel="handleCancelStep"
      />

      <VDivider />
    </VCardText>

    <VCardText v-else>
      <VBtn
        text
        color="primary"
        @click="handleAddStep"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Добавить шаг
      </VBtn>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
import _ from 'lodash';
import DateTime from '../form/DateTime.vue';
import StepForm from '../steps/StepForm.vue';
import StepsList from '../steps/StepsList.vue';

const DEFAULT_STEP = {
  name: null,
  description: null,
  order: 1,
  type: 'STEP_TYPE_INVALID',
  defaultLevel: 'LEVEL_BASIC',
  duration: null,
  parameters: '{}',
  instrumentId: null,
};

export default {
  name: 'ProcedureForm',

  components: { DateTime, StepsList, StepForm },

  props: {
    procedure: {
      type: Object,
      required: true,
    },
    competencies: {
      type: Object,
      required: true,
    },
    skills: {
      type: Object,
      required: true,
    },
    instruments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      stepFormIsHidden: true,
      step: { ...DEFAULT_STEP },
      searchSkills: null,
      searchCompetencies: null,
    };
  },

  computed: {
    normalizedTitle() {
      return 'Добавление процедуры';
    },

    normalizedSubmit() {
      return 'Добавить процедуру';
    },
  },

  watch: {
    searchSkills() {
      this.$emit('update:searchSkills', this.searchSkills);
    },
    searchCompetencies() {
      this.$emit('update:searchCompetencies', this.searchCompetencies);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleAddStep() {
      this.stepFormIsHidden = false;
    },

    // HACK: временно грязное решение
    handleSubmitStep() {
      this.stepFormIsHidden = true;
      /* eslint-disable-next-line vue/no-mutating-props */
      this.procedure.steps.push(_.cloneDeep(this.step));
      this.step = { ...DEFAULT_STEP };
    },

    // HACK: временно грязное решение
    handleCancelStep() {
      this.stepFormIsHidden = true;
      this.step = { ...DEFAULT_STEP };
    },
  },
};
</script>
