<template>
  <!-- FIXME: временно грязное решение -->
  <!-- eslint-disable vue/no-mutating-props -->
  <VCard>
    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                v-model="step.name"
                label="Название"
                placeholder="Введите название"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                v-model="step.description"
                label="Описание"
                placeholder="Введите описание шага"
              />
            </VCol>
          </VRow>

          <!-- Note: в api нет order при создании и обновлении -->
          <!--
          <VRow align="center">
            <VCol>
              <VTextField
                label="Порядок"
                placeholder="Введите порядок"
              />
            </VCol>
          </VRow>
          -->

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="step.type"
                :items="types"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Тип"
                placeholder="Выберите тип"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>

            <VCol>
              <VAutocomplete
                v-model="step.instrumentId"
                :items="instruments"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                :disabled="!['STEP_TYPE_ASSESSMENT', 'STEP_TYPE_PEER_ASSESSMENT'].includes(step.type)"
                label="Инструмент"
                placeholder="Выберите инструмент"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="step.defaultLevel"
                :items="levels"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Уровень"
                placeholder="Выберите уровень"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>

            <VCol>
              <VTextField
                v-model="step.duration"
                type="number"
                label="Продолжительность"
                placeholder="Введите продолжительность"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                v-model="step.parameters"
                label="Параметры JSON"
                placeholder="Введите параметры"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
export default {
  name: 'StepForm',

  props: {
    step: {
      type: Object,
      required: true,
    },
    instruments: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      default() {
        const types = [
          { id: 'STEP_TYPE_INVALID', name: 'STEP_TYPE_INVALID' },
          { id: 'STEP_TYPE_ASSESSMENT', name: 'STEP_TYPE_ASSESSMENT' },
          { id: 'STEP_TYPE_VERIFICATION', name: 'STEP_TYPE_VERIFICATION' },
          { id: 'STEP_TYPE_PEER_ASSESSMENT', name: 'STEP_TYPE_PEER_ASSESSMENT' },
          { id: 'STEP_TYPE_LEARNING', name: 'STEP_TYPE_LEARNING' },
        ];

        return types;
      },
    },
    levels: {
      type: Array,
      default() {
        const levels = [
          { id: 'LEVEL_INVALID', name: 'LEVEL_INVALID' },
          { id: 'LEVEL_NOVICE', name: 'LEVEL_NOVICE' },
          { id: 'LEVEL_BASIC', name: 'LEVEL_BASIC' },
          { id: 'LEVEL_PROFICIENT', name: 'LEVEL_PROFICIENT' },
          { id: 'LEVEL_ADVANCED', name: 'LEVEL_ADVANCED' },
          { id: 'LEVEL_EXPERT', name: 'LEVEL_EXPERT' },
        ];

        return levels;
      },
    },
  },

  computed: {
    normalizedSubmit() {
      return 'Сохранить';
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
