var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VCardText',[_c('VForm',[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextField',{attrs:{"label":"Название","placeholder":"Введите название"},model:{value:(_vm.step.name),callback:function ($$v) {_vm.$set(_vm.step, "name", $$v)},expression:"step.name"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextarea',{attrs:{"label":"Описание","placeholder":"Введите описание шага"},model:{value:(_vm.step.description),callback:function ($$v) {_vm.$set(_vm.step, "description", $$v)},expression:"step.description"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"items":_vm.types,"filter":_vm.customFilter,"item-text":"name","item-value":"id","label":"Тип","placeholder":"Выберите тип"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}]),model:{value:(_vm.step.type),callback:function ($$v) {_vm.$set(_vm.step, "type", $$v)},expression:"step.type"}})],1),_c('VCol',[_c('VAutocomplete',{attrs:{"items":_vm.instruments,"filter":_vm.customFilter,"item-text":"name","item-value":"id","disabled":!['STEP_TYPE_ASSESSMENT', 'STEP_TYPE_PEER_ASSESSMENT'].includes(_vm.step.type),"label":"Инструмент","placeholder":"Выберите инструмент"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}]),model:{value:(_vm.step.instrumentId),callback:function ($$v) {_vm.$set(_vm.step, "instrumentId", $$v)},expression:"step.instrumentId"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"items":_vm.levels,"filter":_vm.customFilter,"item-text":"name","item-value":"id","label":"Уровень","placeholder":"Выберите уровень"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}]),model:{value:(_vm.step.defaultLevel),callback:function ($$v) {_vm.$set(_vm.step, "defaultLevel", $$v)},expression:"step.defaultLevel"}})],1),_c('VCol',[_c('VTextField',{attrs:{"type":"number","label":"Продолжительность","placeholder":"Введите продолжительность"},model:{value:(_vm.step.duration),callback:function ($$v) {_vm.$set(_vm.step, "duration", $$v)},expression:"step.duration"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextarea',{attrs:{"label":"Параметры JSON","placeholder":"Введите параметры"},model:{value:(_vm.step.parameters),callback:function ($$v) {_vm.$set(_vm.step, "parameters", $$v)},expression:"step.parameters"}})],1)],1)],1)],1)],1),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Отмена ")]),_c('VBtn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.normalizedSubmit)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }